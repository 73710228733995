// Current Company Colors
$main-color: #284093;
$secondary-color: #797d83;
$button-glow-color: #284093;
$button-color: #284093;
$button-text-color: white;

// Stealth Colors
$stealth-blue: #284093;
$stealth-white: #ffffff;
$stealth-black: #07031a;
$stealth-lightblue: #2cfefd;
$stealth-turquoise: #20acb7;
$stealth-darkgray: #414549;
$stealth-lightgray: #c4c4c4;
$stealth-yellow: #f6bb42;

// Component Colors
//  - Darkmode
$background-color-darkmode: #212529;
$footer-header-color-darkmode: #252a2f;
//  - Lightmode
$background-color-lightmode: #f5f5f5;
$footer-header-color-lightmode: $stealth-white;
$metrics-background-color: rgba(121, 125, 131, 0.2);

// Nav Bar
$nav-color-hover: #20acb7;
$nav-color: $secondary-color;

// Fonts
$body-font: "Arial", sans-serif;
$main-font-color-darkmode: $stealth-white;
$main-font-color-lightmode: $stealth-black;

$theme-colors__dt: (
  // "success": $green,
  // "warning": $yellow,
);
